import { Container, Row, Col, Card } from "react-bootstrap";
import { Link } from "react-router-dom";

export default function HomeFeature() {
  // const xmasStyles = {
  //   card: {
  //     minHeight: "310px",
  //     background:
  //       "repeating-linear-gradient(90deg, #BD0019, #BD0019 60px, #E3001E 50px, #E3001E 120px)}",
  //   },
  // };

  return (
    <>
      <Container fluid className="bg-dark">
        <Row>
        {/* <Col lg={4} md={4}>
            <Card
              className="position-relative bg-blue clickable text-center text-white my-3 d-flex justify-content-center align-items-center"
                 style={{minHeight:"300px"}}
            >
              <div
                className="p-5"
              >
                <h3 className="text-uppercase lh-1 fw-bold">CarePortal:</h3>
                <h5 className="mb-4">
                A Resource for the Local Church.
                </h5>

                <Link to="/careportal">
                  <button
                    className="btn btn-dark stretched-link"
                  >
                    Learn More
                  </button>
                </Link>
              </div>
            </Card>
          </Col> */}
        <Col lg={4} md={4}>
            <Card
              className="bg-pink position-relative clickable text-center text-white my-3 d-flex justify-content-center align-items-center"
              style={{ minHeight: "300px" }}
            >
              <div className="p-5">

                <h3 className="text-uppercase lh-1 fw-bold">State-by-State Adoption Information</h3>
           
                <Link to="/highlands">
                  <button className="btn btn-dark stretched-link lead">
                    Read more
                  </button>
                </Link>
              </div>
            </Card>
            </Col>
            <Col lg={4} md={4}>

            <Card
              className="bg-purple clickable text-center p-5 my-3 px-md-3 px-lg-5 d-flex justify-content-center align-items-center text-white"
              style={{ minHeight: "300px" }}
            >

                <p className="fw-bold fs-2 mb-5 lh-1">
                Watch What’s Happening
                </p>
                
                <a href="https://www.youtube.com/channel/UC7LHU70Uou3e8DsrNj6CnNA" target="_blank" rel="noopener noreferrer">
                  <button className="btn btn-dark stretched-link lead">
                    Follow Us On YouTube
                  </button>
                </a>
           
            </Card>
/</Col>

                  {/* <Card
                    className="position-relative bg-green clickable text-center text-white p-5 my-3 d-flex justify-content-center align-items-center"
                    style={{ minHeight: "300px" }}
                  >
                              <p className="fw-bold fs-2 mb-5 lh-1">
                              AG Foster Care Network
                </p>
                    <a href="https://agfostercare.network/"
                    target="_blank" rel="noopener noreferrer">
                      <button className="btn btn-dark stretched-link">
                        Learn More
                      </button>
                    </a>
                  </Card> */}


       
 
          {/* <Col lg={4} md={4}>
            <Card
              className="position-relative bg-green clickable text-center text-white my-3 d-flex justify-content-center align-items-center"
                 style={{minHeight:"300px"}}
            >
              <div
                className="p-5"
              >
                <p className="lh-1 fs-2 fw-bold">
                COMP
          <span className="act">ACT</span> Christmas
                </p>

<br />
                <Link to="/christmas">
                  <button
                    className="btn btn-dark stretched-link"
                  >
                    Read More
                  </button>
                </Link>
              </div>
            </Card>
          </Col> */}



          {/* <Col lg={4} md={4}>
            <Card
              className="position-relative bg-blue clickable text-center text-white my-3 d-flex justify-content-center align-items-center"
                 style={{minHeight:"300px"}}
            >
              <div
                className="p-5"
              >
                <h3 className="text-uppercase lh-1 fw-bold">Greet the New Year with generosity</h3>
                <p className="mb-4">
                  Start 2024 with a giving heart. 
                </p>
                <a href="https://giving.ag.org/donate/compact-family-services?fundid=38231"
                    target="_blank" rel="noopener noreferrer">
                      <button className="btn btn-dark stretched-link">
                        Donate here
                      </button>
                    </a>
              </div>
            </Card>
          </Col> */}
          <Col lg={4} md={4}>
            <Card
              className="position-relative bg-blue clickable text-center text-white my-3 d-flex justify-content-center align-items-center"
                 style={{minHeight:"300px"}}
            >
              <div
                className="p-5"
              >
                <h3 className="text-uppercase lh-1 fw-bold">Employment Opportunities</h3>
                <h5 className="mb-4">
                  We're on the look out for team members.
                </h5>

                <Link to="/employment">
                  <button
                    className="btn btn-dark stretched-link"
                  >
                    Read More
                  </button>
                </Link>
              </div>
            </Card>
          </Col>




          {/* <Col lg={4} md={4}>
                  <Card
                    className="position-relative bg-red clickable text-center text-white p-5 my-3 d-flex justify-content-center align-items-center"
                    style={{ minHeight: "300px" }}
                  >
                    <h2 className="fs-4 mb-5">
                    <span className="fw-bold">
                    2024 AG Foster Care Network Conference</span> Early Registration Now Open!
                    </h2>
                    <a href="https://www.agfostercare.network/conference"
                    target="_blank" rel="noopener noreferrer">
                      <button className="btn btn-dark stretched-link">
                        Register here
                      </button>
                    </a>
                  </Card>
                </Col> */}

                
{/* <Col lg={4} md={4}>
            <Card
              className="position-relative bg-blue clickable text-center text-white my-3 d-flex justify-content-center align-items-center"
                 style={{minHeight:"300px"}}
            >
              <div
                className="p-5"
              >
                <h3 className="text-uppercase lh-1 fw-bold">Employment Opportunities</h3>
                <h5 className="mb-4">
                  We're on the look out for team members.
                </h5>

                <Link to="/employment">
                  <button
                    className="btn btn-dark stretched-link"
                  >
                    Read More
                  </button>
                </Link>
              </div>
            </Card>
          </Col> */}

               





       


         
        


{/* 
          <Col lg={4} md={4}>
            <Card
              className="position-relative bg-yellow clickable text-center text-white p-5 my-3 d-flex justify-content-center align-items-center"
              style={{ minHeight: "300px" }}
            >
              <h2 className="fs-3 mb-5">
              Learn more about our FIND Ministry.
              </h2>
              <Link to="/find">
                <button className="btn btn-dark stretched-link">
                  Read More
                </button>
              </Link>
            </Card>
          </Col> */}

        
        </Row>
      </Container>
    </>
  );
}
